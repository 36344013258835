import React from 'react';

export default function PageFooter() {
  const currentYear = new Date().getFullYear()
  return (
    <div id="footer">
    <ul className="copyright">
      <li>&copy; {currentYear} Matthew Wood. All rights reserved.</li>
      <li>
        Made in Durham, North Carolina
      </li>
    </ul>
  </div>
  );
}
