import React from 'react';

import Layout from '../components/Layout';
import PageFooter from '../components/PageFooter';
import SideBar from '../components/SideBar';

import pic_republic_sim from '../assets/images/republic-data-sim.gif';
import pic_republic_anywhere from '../assets/images/republic-anywhere.jpg';
import pic_republic_home from '../assets/images/republic-home.jpg';
import pic_relay from '../assets/images/relay.gif';
import pic_about_me from '../assets/images/about_me.jpg';
import Scroll from '../components/Scroll';

const sections = [
  { id: 'top', name: 'Intro', icon: 'fa-home' },
  { id: 'apps', name: 'Apps', icon: 'fa-tablet' },
  //{ id: 'about', name: 'Firmware', icon: 'fa-code' },
  //{ id: 'contact', name: 'Hardware', icon: 'fa-microchip' },
  { id: 'about', name: 'About Me', icon: 'fa-user' },
  { id: 'contact', name: 'Contact', icon: 'fa-envelope' },
];

const IndexPage = () => (
  <Layout>
    <SideBar sections={sections} />

    <div id="main">
      <section id="top" className="one dark cover">
        <div className="container">
          <header>
            <h2 className="alt">
              Hello World! I'm <strong>Matt</strong>
            </h2>
            <h3 className="alt">
              <strong>Mobile</strong> | <strong>Firmware</strong> | <strong>Systems Design</strong>
            </h3>
            <p>
              My career is a journey through hardware startups, network goliaths, full stack exposure, 
              agency development, bootstrap architecture, and team management.
            </p>
          </header>

          <footer>
            <Scroll type="id" element={'apps'}>
              <a href="#apps" className="button">
                Explore my work
              </a>
            </Scroll>
          </footer>
        </div>
      </section>

      <section id="apps" className="two">
        <div className="container">
          <header>
            <h2>Apps</h2>
          </header>

          <div className="row">
            <div className="col-4 col-12-mobile">
              <article className="item">
                <a href="https://play.google.com/store/apps/details?id=com.republicwireless.tel" className="image fit backdrop">
                  <img src={pic_republic_sim} alt="" />
                </a>
                <header>
                  <h3>Republic Wireless</h3>
                </header>
              </article>
              <article className="item">
                <a href="https://play.google.com/store/apps/details?id=com.relaygo.spectre.companion" className="image fit">
                  <img src={pic_relay} alt="" />
                </a>
                <header>
                  <h3>Relay</h3>
                </header>
              </article>
            </div>
            <div className="col-4 col-12-mobile">
              <article className="item">
                <a href="https://play.google.com/store/apps/details?id=com.republicwireless.messaging" className="image fit">
                  <img src={pic_republic_anywhere} alt="" />
                </a>
                <header>
                  <h3>Anywhere Messaging</h3>
                </header>
              </article>
            </div>
            <div className="col-4 col-12-mobile">
              <article className="item">
                <a className="image fit">
                  <img src={pic_republic_home} alt="" />
                </a>
                <header>
                  <h3>Anywhere Home</h3>
                </header>
              </article>
            </div>
          </div>

          <p>
            <br/>
            Little work happens by a single contributor, but instead is often alongside a team of incredibly talented coworkers who come together to build something awesome.
            This work happens on the backs of open source contributors who put in the extra hours to make building on top of great platforms possible.
            To those who helped in the building of these apps, thank you!
          </p>
        </div>
      </section>

      <section id="about" className="three">
        <div className="container">
          <header>
            <h2>About Me</h2>
          </header>

          <a className="image featured">
            <img src={pic_about_me} alt="" />
          </a>

          <p>
            <br/>
            AVL → BOSTON → BAY AREA → DURHAM
            <br/>
            <br/>
            I learn best at the end of a firehose, with a nonstop flow of code and docs to experiment with.
            I aspire to surround myself with creators that are smarter than myself, get in over my head, and learn from the experience.
            I'll tinker with almost anything: my car, home hvac, or basically anything I can take apart.
            Sometimes I fix it, other times it breaks, but I enjoy learning how it works.
            <br/>
            Outside of work, I'm usually hiking, biking, or on some adventure with my wife Jenny and our dog Annie.
          </p>
        </div>
      </section>

      <section id="contact" className="four">
        <div className="container">
          <header>
            <h2>Contact</h2>
          </header>

          <blockquote>
            <strong>
              Surround yourself with people that push you to do and be better.
              No drama or negativity. Just higher goals and higher motivation.
              Good times and positive energy. No jealousy or hate.
              Simply bringing out the absolute best in each other.
            </strong>
            <br/>
            - Warren Buffet
          </blockquote>

          <p>
            <br/>
            <strong>
              Interested in working together?
              Have an idea you want to run by me?
              Want to grab a beer?
            </strong>
            <br/>
            I'm not so much of a networker, as much as I enjoy meeting fellow developers in the area.
            I like listening to folk's career growth and hearing of their hardest challenges or where they've learned from failures.
            <br/>
            Want to get in touch? Email me at <a href="mailto:matt@mattwood.dev">matt@mattwood.dev</a>
          </p>

          {/* <form method="post" action="#">
            <div className="row">
              <div className="col-6 col-12-mobile">
                <input type="text" name="name" placeholder="Name" />
              </div>
              <div className="col-6 col-12-mobile">
                <input type="text" name="email" placeholder="Email" />
              </div>
              <div className="col-12">
                <textarea name="message" placeholder="Message" />
              </div>
              <div className="col-12">
                <input type="submit" value="Send Message" />
              </div>
            </div>
          </form> */}
        </div>
      </section>
    </div>

    <PageFooter />
  </Layout>
);

export default IndexPage;
